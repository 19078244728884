import type { HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { tap } from "rxjs";
import { ReplicacheService } from "src/app/services";
import { environment } from "src/environments/environment";

export const ReplicacheRefreshInterceptor: HttpInterceptorFn = (req, next) => {
	const apiEndpoints: string[] = [
		environment.communicationsEndpoint,
		environment.calendaringEndpoint,
		environment.inventoryEndpoint,
		environment.coreEndpoint,
		environment.applicationsEndpoint,
		environment.emailMarketingEndpoint,
	];

	const allowedMethods = ["PUT", "POST", "DELETE"];

	const isApiUrl = (url: string): boolean => {
		if (url.includes("last-workspace")) return false;
		return apiEndpoints.some((envUrl) => url.startsWith(envUrl));
	};

	if (isApiUrl(req.url) && allowedMethods.includes(req.method)) {
		const replicache = inject(ReplicacheService);
		return next(req).pipe(
			tap(async (response) => {
				if ("status" in response && response.status === 200)
					await replicache.forcePull();
			}),
		);
	}

	return next(req);
};
