import type { HttpInterceptorFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { from, switchMap, tap } from "rxjs";
import { CognitoService, DeviceService } from "src/app/services";
import { environment } from "src/environments/environment";

export const AuthorizationInterceptor: HttpInterceptorFn = (req, next) => {
	const cognitoService = inject(CognitoService);
	const deviceService = inject(DeviceService);

	const apiEndpoints: string[] = [
		environment.communicationsEndpoint,
		environment.calendaringEndpoint,
		environment.inventoryEndpoint,
		environment.coreEndpoint,
		environment.analyticsEndpoint,
		environment.applicationsEndpoint,
		environment.emailMarketingEndpoint,
		environment.replicache.authorizedEndpoint,
		environment.replicache.pullEndpoint,
		environment.replicache.pushEndpoint,
		environment.workspacesEndpoint,
	];

	const isApiUrl = (url: string): boolean => {
		return apiEndpoints.some((envUrl) => url.startsWith(envUrl));
	};

	const isRequestAuthorized = (request: HttpRequest<any>): boolean => {
		if (!cognitoService.user()) return false;
		const { idToken } = cognitoService.user();
		return !!idToken && isApiUrl(request.url);
	};

	const addAuthorizationHeader = (
		request: HttpRequest<unknown>,
	): HttpRequest<unknown> => {
		const { idToken } = cognitoService.user();
		return request.clone({ setHeaders: { Authorization: idToken } });
	};

	const authenticatedRequest$ = next(addAuthorizationHeader(req)).pipe(
		tap((response) => {
			if ("status" in response && response.status === 200) {
				deviceService.isOnline.set(true);
				deviceService.isAuthorized.set(true);
			}
		}),
	);

	if (!isRequestAuthorized(req)) return next(req);

	if (cognitoService.shouldRefreshSession()) {
		return from(cognitoService.refreshSession()).pipe(
			switchMap(() => authenticatedRequest$),
		);
	}
	return authenticatedRequest$;
};
