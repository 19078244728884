import { inject } from "@angular/core";
import { type CanActivateFn, Router } from "@angular/router";
import { map } from "rxjs";
import { DebugService } from "src/app/services";

export const DebugGuard: CanActivateFn = (route, state) => {
	const debugService = inject(DebugService);
	const router = inject(Router);

	return debugService.enabled$.pipe(
		map((enabled) => {
			if (!enabled) {
				console.warn(
					"DebugGuard: Debug mode is disabled. Redirecting to home page.",
				);
				return router.createUrlTree(["/"]);
			}
			return true;
		}),
	);
};
