import { inject } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { type CanActivateFn, GuardResult, Router } from "@angular/router";
import { map, skipWhile, take } from "rxjs";
import { type Workspace } from "src/app/schema";
import { WorkspaceService } from "src/app/services";

export const WorkspaceGuard: CanActivateFn = (_, state) => {
	const workspaceService = inject(WorkspaceService);
	const router = inject(Router);

	const getGuardResult = (workspaces: Workspace[]): GuardResult => {
		if (state.url.startsWith("/no-workspaces")) {
			return workspaces.length === 0 ? true : router.parseUrl("/");
		}
		if (workspaces.length === 0) return router.parseUrl("/no-workspaces");
		return true;
	}

	return toObservable(workspaceService.workspaces).pipe(
		skipWhile((workspaces) => workspaces === undefined),
		map((workspaces) => workspaces ?? []),
		take(1),
		map(getGuardResult),
	)
};
