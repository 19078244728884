import { provideHttpClient, withInterceptors } from "@angular/common/http";
import {
	APP_INITIALIZER,
	type ApplicationConfig,
	ErrorHandler,
	isDevMode,
	provideExperimentalZonelessChangeDetection,
} from "@angular/core";
import { provideAnimations } from "@angular/platform-browser/animations";
import { RouteReuseStrategy, Router, provideRouter } from "@angular/router";
import { provideServiceWorker } from "@angular/service-worker";
import { TraceService, createErrorHandler } from "@sentry/angular";
import { routes } from "./app.routes";
import {
	AuthorizationInterceptor,
	ReplicacheRefreshInterceptor,
} from "./interceptors";
import { CustomRouteReuseStrategy } from "./utils";

export const appConfig: ApplicationConfig = {
	providers: [
		provideExperimentalZonelessChangeDetection(),
		provideRouter(routes),
		provideAnimations(),
		provideHttpClient(
			withInterceptors([
				AuthorizationInterceptor,
				ReplicacheRefreshInterceptor,
			]),
		),
		provideServiceWorker("ngsw-worker.js", {
			enabled: !isDevMode(),
			registrationStrategy: "registerWhenStable:30000",
		}),
		{
			provide: ErrorHandler,
			useValue: createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => { },
			deps: [TraceService],
			multi: true,
		},
		{
			provide: RouteReuseStrategy,
			useClass: CustomRouteReuseStrategy,
		},
	],
};
