import { type AfterViewInit, Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { ModalModule, NotificationsModule } from "@arch-intelligence/ngx-arch";

import { RouteService } from "./services/meta/route/route.service";
import { OpenReplayService } from "./services/open-replay/open-replay.service";

@Component({
	selector: "app-root",
	standalone: true,
	imports: [RouterOutlet, ModalModule, NotificationsModule],
	templateUrl: "./app.component.html",
})
export class AppComponent implements AfterViewInit {
	constructor(
		private routeService: RouteService,
		private openReplayService: OpenReplayService,
	) {}

	async ngAfterViewInit(): Promise<void> {
		this.routeService.subscribeToRouterEvents();
		const loader = document.querySelector("#loader");
		if (loader && !loader.classList.contains("hidden"))
			loader.classList.add("hidden");
		await this.openReplayService.start();
		await this._deleteOblivionDbIfItExists();
	}

	private async _deleteOblivionDbIfItExists(): Promise<void> {
		if (await this._checkIfOblivionDbStillExists())
			await this._deleteOblivionDb();
	}

	private async _checkIfOblivionDbStillExists(): Promise<boolean> {
		try {
			return indexedDB
				.databases()
				.then((dbs) => dbs.some((db) => db.name === "OblivionDB"));
		} catch (error) {
			console.error("Error checking if OblivionDB database exists", error);
			return false;
		}
	}

	private async _deleteOblivionDb(): Promise<void> {
		try {
			const request = indexedDB.deleteDatabase("OblivionDB");
			request.onsuccess = () => console.log("OblivionDB database deleted");
			request.onerror = (event: Event) =>
				console.error("Error deleting OblivionDB database", event);
		} catch (error) {
			console.error("Error deleting OblivionDB database", error);
		}
	}
}
