import { inject } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { type CanActivateFn, type GuardResult, Router } from "@angular/router";
import { map, skipWhile, take } from "rxjs";
import { CognitoService } from "src/app/services";

export const AuthGuard: CanActivateFn = (_, state) => {
	const cognitoService = inject(CognitoService);
	const router = inject(Router);

	const getGuardResult = (isAuthenticated: boolean | null): GuardResult => {
		if (state.url.startsWith("/auth")) {
			switch (isAuthenticated) {
				case true:
					if (state.url.startsWith("/auth/sign-out")) return true;
					if (
						state.url.startsWith("/auth/change-password") &&
						cognitoService.newPasswordRequired() === true
					)
						return true;
					break;
				case false:
					if (state.url.startsWith("/auth/sign-in")) return true;
					break;
			}
			return router.parseUrl("/");
		}
		return isAuthenticated === true ? true : router.parseUrl("/auth/sign-in");
	};

	return toObservable(cognitoService.isAuthenticated).pipe(
		skipWhile((isAuthenticated) => isAuthenticated === null),
		take(1),
		map(getGuardResult),
	);
};
