import type { Routes } from "@angular/router";
import { AuthGuard, DebugGuard, WorkspaceGuard } from "./guards";

export const publicRoutes: Routes = [
	{
		path: "auth",
		canActivate: [AuthGuard],
		children: [
			{
				path: "sign-in",
				title: "Sign In · Arch",
				loadComponent: () =>
					import("./modules/auth/sign-in/sign-in.component").then(
						(m) => m.SignInComponent,
					),
			},
			{
				path: "sign-out",
				title: "Sign Out · Arch",
				loadComponent: () =>
					import("./modules/auth/sign-out/sign-out.component").then(
						(m) => m.SignOutComponent,
					),
			},
			{
				path: "change-password",
				title: "Change Password · Arch",
				loadComponent: () =>
					import(
						"./modules/auth/change-password/change-password.component"
					).then((m) => m.ChangePasswordComponent),
			},
		],
	},
];

export const protectedRoutes: Routes = [
	{
		path: "",
		canActivate: [AuthGuard],
		children: [
			{
				path: "",
				canActivate: [WorkspaceGuard],
				loadComponent: () =>
					import("./modules/layout/layout/layout.component").then(
						(m) => m.LayoutComponent,
					),
				children: [
					{
						path: "",
						pathMatch: "full",
						redirectTo: "home",
					},
					{
						path: "home",
						title: "Home · Arch",
						loadComponent: () =>
							import("./modules/home/home.component").then(
								(m) => m.HomeComponent,
							),
					},
					{
						path: "calendar",
						title: "Calendar · Arch",
						children: [
							{
								path: "",
								title: "Calendar · Arch",
								data: {
									title: "Calendar",
								},
								loadComponent: () =>
									import("./modules/calendar/calendar/calendar.component").then(
										(m) => m.CalendarComponent,
									),
							},
							{
								path: "availability",
								title: "Update Availability · Arch",
								data: {
									title: "Availability",
								},
								loadComponent: () =>
									import(
										"./modules/calendar/components/update-availability/update-availability.component"
									).then((m) => m.UpdateAvailabilityComponent),
							},
						],
					},
					{
						path: "email-marketing",
						title: "Email Marketing · Arch",
						data: {
							title: "Email Marketing",
						},
						loadComponent: () =>
							import(
								"./modules/email-marketing/email-marketing/email-marketing.component"
							).then((m) => m.EmailMarketingComponent),
						children: [
							{
								path: "",
								redirectTo: "sends",
								pathMatch: "full",
							},
							{
								path: "sends",
								title: "Sends · Arch",
								data: {
									title: "Sends",
								},
								loadComponent: () =>
									import(
										"./modules/email-marketing/sends/sends.component"
									).then((m) => m.SendsComponent),
							},
							{
								path: "sends/create",
								title: "Create Send · Arch",
								data: {
									title: "Create Send",
								},
								loadComponent: () =>
									import(
										"./modules/email-marketing/components/create-send/create-send.component"
									).then((m) => m.CreateSendComponent),
							},
							{
								path: "audiences",
								title: "Audiences · Arch",
								data: {
									title: "Audiences",
								},
								loadComponent: () =>
									import(
										"./modules/email-marketing/audiences/audiences.component"
									).then((m) => m.AudiencesComponent),
							},
							{
								path: "audiences/:audienceId/edit",
								title: "Edit Audience · Arch",
								data: {
									title: "Edit Audience",
								},
								loadComponent: () =>
									import(
										"./modules/email-marketing/components/edit-audience/edit-audience.component"
									).then((m) => m.EditAudienceComponent),
							},
							{
								path: "templates",
								title: "Templates · Arch",
								data: {
									title: "Templates",
								},
								loadComponent: () =>
									import(
										"./modules/email-marketing/templates/templates.component"
									).then((m) => m.TemplatesComponent),
							},
							{
								path: "templates/:templateId/edit",
								title: "Edit Template · Arch",
								data: {
									title: "Edit Template",
								},
								loadComponent: () =>
									import(
										"./modules/email-marketing/template-editor/template-editor.component"
									).then((m) => m.TemplateEditorComponent),
							},
						],
					},
					{
						path: "inbox",
						children: [
							{
								path: "",
								title: "Inbox · Arch",
								data: {
									title: "Inbox",
									reuseRoute: true,
								},
								loadComponent: () =>
									import("./modules/inbox/inbox/inbox.component").then(
										(m) => m.InboxComponent,
									),
							},
							{
								path: ":leadId",
								title: "Inbox · Arch",
								data: {
									title: "Inbox",
									reuseRoute: true,
								},
								loadComponent: () =>
									import("./modules/inbox/inbox/inbox.component").then(
										(m) => m.InboxComponent,
									),
							},
						],
					},
					{
						path: "leads",
						children: [
							{
								path: "",
								title: "Leads · Arch",
								data: {
									title: "Leads",
								},
								loadComponent: () =>
									import("./modules/leads/leads/leads.component").then(
										(m) => m.LeadsComponent,
									),
							},
							{
								path: "waitlist",
								title: "Waitlist · Arch",
								data: {
									title: "Waitlist",
								},
								loadComponent: () =>
									import("./modules/leads/waitlist/waitlist.component").then(
										(m) => m.WaitlistComponent,
									),
							},
						],
					},
					{
						path: "applications",
						children: [
							{
								path: "",
								title: "Applications · Arch",
								data: {
									title: "Applications",
								},
								loadComponent: () =>
									import(
										"./modules/applications/applications/applications.component"
									).then((m) => m.ApplicationsComponent),
							},
							{
								path: "add",
								title: "Add Application · Arch",
								data: {
									title: "Add Application",
								},
								loadComponent: () =>
									import(
										"./modules/applications/create-application/create-application/create-application.component"
									).then((m) => m.CreateApplicationComponent),
							},
							{
								path: "review/:applicationId",
								title: "Review Application · Arch",
								data: {
									title: "Review Application",
								},
								loadComponent: () =>
									import(
										"./modules/applications/review-application/review-application/review-application.component"
									).then((m) => m.ReviewApplicationComponent),
							},
						],
					},
					{
						path: "listings",
						children: [
							{
								path: "",
								title: "Listings · Arch",
								data: {
									title: "Listings",
								},
								loadComponent: () =>
									import("./modules/listings/listings/listings.component").then(
										(m) => m.ListingsComponent,
									),
							},
							{
								path: "view/:unitId/edit",
								title: "Edit Listing · Arch",
								data: {
									title: "Edit Listing",
								},
								loadComponent: () =>
									import(
										"./modules/listings/components/edit-unit/edit-unit.component"
									).then((m) => m.EditUnitComponent),
							},
						],
					},
					{
						path: "analytics",
						title: "Analytics · Arch",
						data: {
							title: "Analytics",
						},
						loadComponent: () =>
							import("./modules/analytics/analytics/analytics.component").then(
								(m) => m.AnalyticsComponent,
							),
					},
					{
						path: "content",
						title: "Content · Arch",
						data: {
							title: "Content",
						},
						loadComponent: () =>
							import("./modules/content/content/content.component").then(
								(m) => m.ContentComponent,
							),
					},
					{
						path: "settings",
						loadComponent: () =>
							import("./modules/settings/settings/settings.component").then(
								(m) => m.SettingsComponent,
							),
						children: [
							{
								path: "",
								redirectTo: "property",
								pathMatch: "full",
							},
							{
								path: "property",
								title: "Property Settings · Arch",
								loadComponent: () =>
									import(
										"./modules/settings/components/property-settings/property-settings.component"
									).then((m) => m.PropertySettingsComponent),
							},
							{
								path: "applications",
								title: "Application Settings · Arch",
								loadComponent: () =>
									import(
										"./modules/settings/components/application-settings/application-settings.component"
									).then((m) => m.ApplicationSettingsComponent),
							},
						],
					},
					{
						path: "debug",
						title: "Debug · Arch",
						loadComponent: () =>
							import("./modules/debug/debug.component").then(
								(m) => m.DebugComponent,
							),
						canActivate: [DebugGuard],
					},
				],
			},
			{
				path: "no-workspaces",
				title: "No Workspaces · Arch",
				canActivate: [WorkspaceGuard],
				loadComponent: () =>
					import("./components/no-workspaces/no-workspaces.component").then(
						(m) => m.NoWorkspacesComponent,
					),
			},
			{
				path: "**",
				redirectTo: "home",
				pathMatch: "full",
			},
		],
	},
];

export const routes: Routes = [...publicRoutes, ...protectedRoutes];
