import { enableProdMode } from "@angular/core";
import { bootstrapApplication } from "@angular/platform-browser";
import * as Sentry from "@sentry/angular";

import { AppComponent } from "./app/app.component";
import { appConfig } from "./app/app.config";
import { environment } from "./environments/environment";

Sentry.init({
	enabled: environment.production,
	dsn: "https://79afc7bc6b764e2088f8e63d7ad274d3@o4504989228990464.ingest.sentry.io/4505047004020736",
	environment: environment.production ? "production" : "dev",
	replaysSessionSampleRate: environment.production ? 1.0 : 0,
	replaysOnErrorSampleRate: environment.production ? 1.0 : 0,
	integrations: [Sentry.browserTracingIntegration()],
	tracePropagationTargets: [
		"http://localhost:4200",
		"http://localhost:3800",
		"https://platform.arch.app",
		"https://platform.dev.arch.app",
	],
	// Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
	tracesSampleRate: 0.5,
});

if (environment.production) enableProdMode();

bootstrapApplication(AppComponent, appConfig).catch((error) =>
	console.error(error),
);
